import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"sm-padding": "60px 0 60px 0",
	"padding": "80px 0 0 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "70%",
			"flex-direction": "column",
			"align-items": "center",
			"margin": "0 auto",
			"lg-width": "100%",
			"lg-align-items": "center",
			"lg-margin": "0px 0px 0px 0px",
			"sm-margin": "0px 0px 0px 0px",
			"sm-padding": "0px 0px 0px 0px",
			"padding": "16px 16px 16px 0px",
			"justify-content": "center",
			"lg-order": "1"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 24px 0px",
			"color": "--dark",
			"font": "--headline2",
			"lg-text-align": "center",
			"sm-font": "normal 700 42px/1.2 \"Source Sans Pro\", sans-serif",
			"children": "Timeless Motors Hakkında Daha Fazlasını Keşfedin",
			"text-align": "center"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 40px 0px",
			"color": "--greyD3",
			"font": "--lead",
			"text-align": "center",
			"children": "Kulübümüzün etik değerleri, üyelik avantajları ve sunduğumuz benzersiz deneyimler hakkında daha fazla bilgi edinin:"
		}
	}
};

const Cta = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Cta, { ...Section,
	defaultProps,
	overrides
});
export default Cta;